import {
    ViewerApp,
    AssetManagerPlugin,
    GBufferPlugin,
    TonemapPlugin,
    DiamondPlugin,
    SSRPlugin,
    SSAOPlugin,
    BloomPlugin,
    GammaCorrectionPlugin,
    mobileAndTabletCheck,
    // addBasePlugins,
    ITexture, TweakpaneUiPlugin, AssetManagerBasicPopupPlugin, CanvasSnipperPlugin,
    IViewerPlugin,
    Color,
    ColorRepresentation,
    ToneMapping, // Import THREE.js internals
    // Texture, // Import THREE.js internals
} from "webgi";
import "./styles.css";
import gsap from "gsap";

//panoramica

// gsap.registerPlugin(ScrollTrigger)


// export default function setupViewer() {
async function setupViewer() {

    const isMobile = mobileAndTabletCheck()
    const velAnimation = 1

    /************************************************************************/
    /************************************************************************/
    /************************************************************************/

    if (!isMobile) {

        //////////////////////////////////////////////////////////////////////
        /////////////////////// Load Background //////////////////////////////
        //////////////////////////////////////////////////////////////////////
        const viewer = new ViewerApp({
            canvas: document.getElementById('webgi-background-canvas') as HTMLCanvasElement,
            useRgbm: false,
        })

        const manager = await viewer.addPlugin(AssetManagerPlugin)
        const camera = viewer.scene.activeCamera
        const position = camera.position
        const target = camera.target

        await viewer.addPlugin(AssetManagerBasicPopupPlugin)

        // Add plugins individually.
        // await viewer.addPlugin(GBufferPlugin)
        // await viewer.addPlugin(new ProgressivePlugin(32))
        await viewer.addPlugin(new TonemapPlugin(!viewer.useRgbm))
        await viewer.addPlugin(GammaCorrectionPlugin)
        await viewer.addPlugin(SSRPlugin)
        await viewer.addPlugin(DiamondPlugin)
        await viewer.addPlugin(SSAOPlugin)
        await viewer.addPlugin(BloomPlugin)

        await viewer.addPlugin(CanvasSnipperPlugin)

        viewer.renderer.refreshPipeline()

        await manager.addFromPath("./assets/alico_scene.glb")

        viewer.scene.activeCamera.setCameraOptions({ controlsEnabled: false, })

        //////////////////////////////////////////////////////////////////////
        /////////////////////// Load Home Obj1 ///////////////////////////////
        //////////////////////////////////////////////////////////////////////

        const viewer2 = new ViewerApp({
            canvas: document.getElementById('obj_canvas_1') as HTMLCanvasElement,
            useRgbm: false,
        })

        const manager2 = await viewer2.addPlugin(AssetManagerPlugin)
        const camera2 = viewer2.scene.activeCamera
        const position2 = camera2.position
        const target2 = camera2.target

        await viewer2.addPlugin(AssetManagerBasicPopupPlugin)

        // Add plugins individually.
        // await viewer2.addPlugin(GBufferPlugin)
        // await viewer.addPlugin(new ProgressivePlugin(32))
        await viewer2.addPlugin(new TonemapPlugin(!viewer.useRgbm))
        await viewer2.addPlugin(GammaCorrectionPlugin)
        await viewer2.addPlugin(SSRPlugin)
        await viewer2.addPlugin(DiamondPlugin)
        await viewer2.addPlugin(SSAOPlugin)
        await viewer2.addPlugin(BloomPlugin)

        await viewer2.addPlugin(CanvasSnipperPlugin)

        viewer2.renderer.refreshPipeline()

        const randNumber = Math.floor(Math.random() * (3 - 1 + 1)) + 1
        // console.log(randNumber)

        if (randNumber === 1) {
            await manager2.addFromPath("./assets/alianca.glb")
            const homeTitle = "title 1"
        } else if (randNumber === 2) {
            await manager2.addFromPath("./assets/suzuki.glb")
            const homeTitle = "title 2"
        } else if (randNumber === 3) {
            await manager2.addFromPath("./assets/panoramica.glb")
            const homeTitle = "title 3"
        }

        viewer2.scene.activeCamera.setCameraOptions({ controlsEnabled: true })
        const controlsViewer2 = viewer2.scene.activeCamera.controls
        controlsViewer2.enableZoom = false

        let needsUpdate = true;

        function onUpdate() {
            needsUpdate = true
            viewer.renderer.resetShadows()
        }

        viewer.addEventListener('preFrame', () => {
            if (needsUpdate) {
                camera.positionTargetUpdated(true)
                needsUpdate = false
            }
        })


    } else {
        // console.log("No celular")

        const bgImageHome = document.getElementById('obj_canvas_1')
        const randNumber = Math.floor(Math.random() * (3 - 1 + 1)) + 1
        if (randNumber === 1) {
            bgImageHome?.style.backgroundImage = "url('assets/home_bg_diamond.jpg')"
        } else if (randNumber === 2) {
            bgImageHome?.style.backgroundImage = "url('assets/home_bg_suzuki.jpg')"
        } else if (randNumber === 3) {
            bgImageHome?.style.backgroundImage = "url('assets/home_bg_panoramica.jpg')"
        }
        bgImageHome?.style.backgroundPosition = "center center"
        bgImageHome?.style.backgroundSize = "cover"
        bgImageHome?.style.backgroundRepeat = "no-repeat"

        const bgImageContact = document.getElementById('div_contact')
        bgImageContact?.style.backgroundImage = "url('assets/contact_bg_website.jpg')"
        bgImageContact?.style.backgroundPosition = "center center"
        bgImageContact?.style.backgroundSize = "cover"
        bgImageContact?.style.backgroundRepeat = "no-repeat"

    }

    /************************************************************************/
    /************************************************************************/
    /************************************************************************/

    let selectedMenu = 'home'
    let actualMenu = 'home'

    const contHome = document.querySelector('.div_home') as HTMLElement
    const contProjects = document.querySelector('.div_projects') as HTMLElement
    const contAbout = document.querySelector('.div_about') as HTMLElement
    const contContact = document.querySelector('.div_contact') as HTMLElement

    function changeCategory(e: any) {
        //Step 1

        // console.log("actual: " + actualMenu)

        actualMenu = selectedMenu
        selectedMenu = e

        // console.log("selected: " + selectedMenu)
        // console.log("-------")

        if (actualMenu !== e) {
            /******************/

            // console.log('cat: ' + selectedMenu)

            //Step 2

            // console.log("selected " + e)
            const actualDiv = ".div_" + actualMenu
            const selectedDiv = ".div_" + selectedMenu

            if (e === "home") {
                if (actualMenu !== "projects") {
                    gsap.to(".div_projects", { y: "100%", duration: 0 })
                    contProjects.classList.remove("div_front")
                    contProjects.classList.add("div_behind")
                }
                if (actualMenu !== "about") {
                    gsap.to(".div_about", { y: "100%", duration: 0 })
                    contAbout.classList.remove("div_front")
                    contAbout.classList.add("div_behind")
                }
                if (actualMenu !== "contact") {
                    gsap.to(".div_contact", { y: "100%", duration: 0 })
                    contContact.classList.remove("div_front")
                    contContact.classList.add("div_behind")
                }
                contHome.classList.remove("div_behind")
                contHome.classList.add("div_front")
            }
            if (e === "projects") {
                if (actualMenu !== "home") {
                    gsap.to(".div_home", { y: "100%", duration: 0 })
                    contHome.classList.remove("div_front")
                    contHome.classList.add("div_behind")
                }
                if (actualMenu !== "about") {
                    gsap.to(".div_about", { y: "100%", duration: 0 })
                    contAbout.classList.remove("div_front")
                    contAbout.classList.add("div_behind")
                }
                if (actualMenu !== "contact") {
                    gsap.to(".div_contact", { y: "100%", duration: 0 })
                    contContact.classList.remove("div_front")
                    contContact.classList.add("div_behind")
                }
                contProjects.classList.remove("div_behind")
                contProjects.classList.add("div_front")
                // console.log('foi projects')
            }
            if (e === "about") {
                if (actualMenu !== "home") {
                    gsap.to(".div_home", { y: "100%", duration: 0 })
                    contHome.classList.remove("div_front")
                    contHome.classList.add("div_behind")
                }
                if (actualMenu !== "projects") {
                    gsap.to(".div_projects", { y: "100%", duration: 0 })
                    contProjects.classList.remove("div_front")
                    contProjects.classList.add("div_behind")
                }
                if (actualMenu !== "contact") {
                    gsap.to(".div_contact", { y: "100%", duration: 0 })
                    contContact.classList.remove("div_front")
                    contContact.classList.add("div_behind")
                }
                contAbout.classList.remove("div_behind")
                contAbout.classList.add("div_front")
                // console.log('foi about')
            }
            if (e === "contact") {
                if (actualMenu !== "home") {
                    gsap.to(".div_home", { y: "100%", duration: 0 })
                    contHome.classList.remove("div_front")
                    contHome.classList.add("div_behind")
                }
                if (actualMenu !== "projects") {
                    gsap.to(".div_projects", { y: "100%", duration: 0 })
                    contProjects.classList.remove("div_front")
                    contProjects.classList.add("div_behind")
                }
                if (actualMenu !== "about") {
                    gsap.to(".div_about", { y: "100%", duration: 0 })
                    contAbout.classList.remove("div_front")
                    contAbout.classList.add("div_behind")
                }
                contContact.classList.remove("div_behind")
                contContact.classList.add("div_front")
                // console.log('foi contact')
            }

            //Step 3
            gsap.to(actualDiv, { y: "-100%", duration: velAnimation })
            gsap.to(selectedDiv, { y: "0%", duration: velAnimation })
            /******************/
        }

    }

    document.querySelector('.logo_menu')?.addEventListener('click', () => {
        changeCategory('home')
        // controlsViewer2.enableRotate = true
    })

    document.querySelector('.menu_projetos')?.addEventListener('click', () => {
        changeCategory('projects')
        if (!isMobile) {
            gsap.to(position, { x: isMobile ? -2.65 : -1.95, y: isMobile ? 2.86 : 0.02, z: isMobile ? -4.27 : -2.14, duration: velAnimation, onUpdate })
            gsap.to(target, { x: isMobile ? 1.43 : 0.81, y: isMobile ? -0.99 : -0.27, z: isMobile ? -0.30 : 0.26, duration: velAnimation, onUpdate })
        }
    })

    document.querySelector('.menu_sobre')?.addEventListener('click', () => {
        changeCategory('about')
        if (!isMobile) {
            gsap.to(position, { x: isMobile ? -2.65 : 1.83, y: isMobile ? 2.86 : 0.16, z: isMobile ? -4.27 : 2.63, duration: velAnimation, onUpdate })
            gsap.to(target, { x: isMobile ? 1.43 : -0.02, y: isMobile ? -0.99 : -0.22, z: isMobile ? -0.30 : 1.19, duration: velAnimation, onUpdate })
        }
    })

    document.querySelector('.menu_contato')?.addEventListener('click', () => {
        changeCategory('contact')
        if (!isMobile) {
            gsap.to(position, { x: isMobile ? -3.79 : -1.93, y: isMobile ? 1.52 : -0.01, z: isMobile ? 2.86 : 2.05, duration: velAnimation, onUpdate })
            gsap.to(target, { x: isMobile ? -0.40 : -0.25, y: isMobile ? -0.43 : -0.26, z: isMobile ? 0.12 : -1.32, duration: velAnimation, onUpdate })
        }
    })

    /*******************************/
    /*******************************/

    /*
    window.addEventListener('resize', testFunction);
    
    function testFunction() {
        const divHome = document.querySelector('.div_home') as HTMLElement
        console.log(divHome.offsetWidth)
    }
    */

}

setupViewer()